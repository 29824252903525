.admin-order-accepted-order-enter-amount{
    font-size:16px;
    margin:0px;
    color:#212d45;
    width:10%;
    overflow: hidden;
    height:100%;
    display:flex;
    justify-content: center;
    align-items: center;
}
.credit-card-form-main-container{
    background-image:url('../utlis/credit-bg.png') !important;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding:5%;
}
.credit-card-form-sub-container{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    /* height: 95vh; */
    width: 90%;
    border-radius: 20px;
}
.credit-card-form-container{
    padding: 20px;
    border: 2px solid #f2f2f2;
    border-radius: 20px;
    background-color: #fff;
    margin: 0px 10px;
}
.rccs__card{
    width: 350px!important;
    height: 35vh!important;
}
 
@media (max-width: 1200px) {
    .credit-card-form-sub-container {
      width: 80%;
      padding: 5%;
    }
  }
 
  @media (max-width: 991px) {
    .credit-card-form-sub-container {
      flex-direction: column;
      height: auto;
      padding: 5%;
    }
  }
 
  @media (max-width: 767px) {
    .credit-card-form-sub-container {
      display:  flex;
      flex-direction: column;
      width: 100%;
    }
  }

  .amount-text{
    font-weight: bold;
    font-size:18px;
    margin-right: 35px;
  }
.customer-signin-sub-container{
  background-image: url('../utlis/signup-bg-2.png') !important;
  background-size: cover;
  height:100vh;
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;
}
.customer-signin-div-container{
  display:flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  height:90vh;
  width:92%;
  border-radius:20px;
}
@media (max-width:912px){
  .customer-signin-div-container{
    height:90vh;
  }
}
.customer-signin-form-main-container {
    width: 30%;
    margin: 0px 10px;
    padding: 10px 16px 10px 10px;
    border: 2px solid #f2f2f2;
    border-radius: 20px;
    
  }
  .customer-signin-form-heading-container{
    font-size:22px;
    font-weight:600;
    color:hsl(220, 35%, 20%);
    margin:10px 0px 4px 0px;
  }
  .customer-singin-form-container{
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .customer-signin-form-group-container {
    margin-bottom: 10px;
    width:80%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    
  }
  
.customer-singnin-form-lable-container {
    margin-bottom: 5px;
    font-size:18px;
    color:#212d45;
    margin:0px;
  }
 .customer-signin-input-container{
    width: 95%;
    padding: 8px;
    font-size: 16px;
    border: none;
    border-radius: 7px;
    background-color: #f2f2f2;
    outline:none;
  }
  
.customer-signin-form-button-container {
    background-color: #212d45;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s;
  }
  .customer-signin-form-button-container:hover{
    border:1px solid #212d45;
    color:#212d45;
    background-color: #fff;
    transition:0.3s ease-in-out;
  }
  
  .error-message{
    font-size:12px;
    color:#ff0000;
  }
  @media  (min-width: 512px) and ( max-width: 768px) {
    .customer-signin-form-main-container {
        width: 60%;
      
      }
  }
  @media (max-width:512px){
    .customer-signin-form-main-container {
        width: 100%;
      
      }
  }
@media (max-width: 768px) {
    .customer-signin-form-heading-container {
      font-size: 24px;
    }
  }
  
  @media (min-width: 769px) and (max-width: 1024px) {
    .customer-signin-form-main-container{
      width: 40%;
    }
  }
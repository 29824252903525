.forgot-password-background-container{
  background-image: url('../utlis/signup-bg-2.png') !important;
  background-size: cover;
  height:100vh;
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;
}

.forgot-password-main-container {
  display:flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  height:80vh;
  width:90%;
  border-radius:20px;
  }
 
  .forgot-password-container {
    background-color: #fff;
    padding: 40px 40px;
    border-radius: 8px;
    margin:0px 10px;
    border:2px solid #f2f2f2;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
 
  .forgot-password-heading {
    text-align: center;
    color: #333;
    font-weight: bold;
    font-size: 22px;
  }
 
  .forgot-password-form-container {
    width: 300px;
  }
 
  .forgot-password-lable-container {
    display: block;
    margin-bottom: 8px;
    color: black;

  }
 
  .forgot-passsword-input-container {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    border: none;
    background-color: #f2f2f2;
    border-radius: 4px;
    box-sizing: border-box;
  }
 
  .forgot-password-button-container {
    text-align: center;
  }
 
  .forgot-password-button {
    width: 100%;
    padding: 10px;
    background-color: #212d45;
    color:#fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 20px;
  }
 
  .forgot-password-button:hover {
    background-color: #f5de69;
  }
 
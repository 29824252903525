/* .customer-signin-div-container{
    display:flex;
  justify-content: center;
  align-items: center;
  height:100vh;
} */
.customer-staff-customer-button-container{
    display:flex;
    justify-content: space-between;
    width:100%;
  }
  .customer-staff-button{
    border:none;
    width:45%;
    padding:8px 0px;
    border:1px solid #4b4f58;
    border-radius:4px;
    font-size:16px;
    color:#212d45;
    cursor:pointer;
    font-weight:600;
    background-color: #fff;
    letter-spacing: 0.5px;
  }
  .customer-customer-button{
    border:none;
    width:45%;
    padding:8px 0px;
    background-color: #4b4f58;
    border-radius:4px;
    font-size:16px;
    color:#fff;
    cursor:pointer;
    font-weight:600;
    letter-spacing: 0.5px;
  }
  .error-message{
    font-size:12px;
    color:#ff0000;
    margin:0px;
  }

  .logo-image-axxpress {
    width: 200px;
    height: auto;
    margin : 10px;
    display: block; 
    margin: 0 auto; 
    transition: transform 0.3s ease-in-out;
  }
  
  
  
.status-debit {
  color: #ff0000;
}
.status-credit {
  color: #33cc33;
}
.wallet-add-heading{
  font-size:24px;
  margin:0px;
  color:#212d45;
}
.wallet-add-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
}

.wallet-add-sub-container {
  display: flex;
  flex-direction: column;
  gap:20px;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width:100%;
}



.wallet-add-input {
  width:98%;
  padding:10px 0px 10px 10px;
  border-radius: 8px;
  outline:none;
  border:none;
  border:1px solid #ccc;
}

.wallet-add-field-container select {
  width: 100%;
  padding: 10px;
  border:none;
  border-radius: 8px;
  border:1px solid #ccc;
  outline:none;
}

.wallet-add-field-container select option {
  padding: 10px;
  background-color: #fff;
}

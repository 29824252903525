.navbar {
    background-color: #ffc03d;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 0.8;
  }
  
  .menu-bars {
    margin-left: 2rem;
    font-size: 20px;
    background: none;
    color:#212d45;
  }
  
  .nav-menu {
    background-color: #ffc03d;
    width: 270px;
    height: 100vh;
    overflow-y:scroll;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -80%;
    transition: 850ms;
    opacity: 0.8;
  }
  .nav-menu::-webkit-scrollbar{
    width:0.5px;
    height:0.5px;
  }
  .nav-menu.active {
    left: 0;
    transition: 350ms;
  }
  
  .nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    height: 50px;
    width:max-content;
    margin-bottom:4px;
    width:170px;
    min-width:max-content;
    
  }
  
  .nav-text{
    text-decoration: none;
    color: #212d45;
    font-size: 18px;
    font-weight:600;
    display: flex;
    gap:5px;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
    cursor:pointer;
  }
  
  .nav-text:hover {
    background-color: #212d45;
    color:#fff;
  }
  
  .nav-menu-items {
    width: 100%;
  }
  
  .navbar-toggle {
    /* background-color: #ffc03d; */
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    opacity: 1;
  }
  
  .span {
    margin-left: 16px;
  }
  .navbar-logout-button-container{
    display:flex;
    align-items:center;
    gap:20px;
  }
  .navbar-nav-item-name{
    color:#fff;
    font-size:18px;
    font-weight:600;
    margin:0px;
  }
  .navbar-logout-button{
    background-color: #212d45;
    border-radius:20px;
    padding:8px 20px;
    color:#fff;
    margin-right:20px;
    font-size:16px;
    cursor:pointer;
    min-width:max-content;
    border:none;
  }
  .content-container {
    width: 100%; /* Adjust for the width of the closed sidebar */
 /* Initial margin when the sidebar is closed */
  /* Add smooth transitions */
    background-color: #fff;
    opacity: 1;
    transition: 0.1s;
  }
  
  .content-container.shifted {
    width: calc(100% - 268px); /* Adjust for the width of the opened sidebar */
    margin-left: 268px; /* Margin when the sidebar is opened */
    transition: 0.6s;
  }
  .active-nav-item{
    background-color: #212d45;
    color:#fff !important;
  }
  .navbar-container{
    background-image: url('https://www.goldmansachs.com/intelligence/pages/the-long-and-short-of-it/paper-and-packaging-industry/800x450.png');
    background-size: cover;
    width:100%;
    min-height:100vh;
  }
  .toggle{
    opacity:0;
  }
  .toggle-icon{
    color:#212d45;
    opacity: 1;
  }
  .sidebar-icon{
    color:#212d45;
  }
  .nav-text:hover .sidebar-icon{
    color:#fff;
  }
  .sidebar-icon-active{
    color:#fff;
  }
  .navbar-logout-button-icon{
    display:none;
    color:#212d45;
    font-size:20px;
    margin-right:20px;
    cursor:pointer;
  }
  @media (max-width:512px){
    .navbar-nav-item-name{
      font-size:14px;
    }
    .navbar-logout-button-icon{
      display:block;
    }
    .navbar-logout-button{
      display:none;
    }
  }
.customer-create-password-main-container{
    display:flex;
    height:100vh;
    align-items: center;
}
.customer-create-password-container{
    display:flex;
    justify-content: center;
    align-items: center;
    width:90%;
}
.dimensions-details-form-container{
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 20px;
    width:100%;
}
.dimension-flex{
    display:flex;
    flex-wrap:wrap;
    gap:20px;
    width:100%;
}
.dimensions-details-input-container{
    width:15%;
}
.dimension-detail-select-container{
    display:flex;
    align-items: center;
    margin-top: 9px;
    width:100%;
}
.dimensions-details-input{
  width: 50%;
  padding:7px 0px;
  margin-right: 5px;
  border-radius: 5px;
  border-width: 1px;
  border:1px solid #212d45;
  text-align: center;
}
.dimensions-details-select{
  padding:6px 2px;
  color: #212d45;
  font-weight: bold;
  font-size: 12px;
  width: 50%;
  border:1px solid #212d45;
  border-radius:4px;
}
.dimension-details-input-container-1{
 display:flex;
  flex-direction:column;
  align-items:center;
  gap:4px;
  width:100px;
  
}
.dimensions-details-input-1{
  padding:8px 0px;
  border-radius: 5px;
  border-width: 1px;
  border:1px solid #212d45;
  text-align: center;
  width:100%;
}
.dimensions-details-button{
  padding:8px 20px;
  color: #fff;
  font-weight: 500;
  font-size: 15px;
  border-radius: 20px;
  outline: none;
  border-width: 0px;
  background-color: #212d45;
  cursor:pointer;
  margin-top:10px;
}
@media (max-width:768px){
    .dimension-flex{
        flex-direction: column;
        align-items: center;
    }
}
.dimensions-main-container {
  display: flex;
  align-items: center;
}

.dimensions-form-container {
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 20px;
  width:100%;

}
.dimension-flex-container{
  display:flex;
  flex-wrap:wrap;
}

.dimensions-input-container {
  margin-bottom: 10px;
  width:350px;
}

.dimensions-label-text {
  font-size: 18px;
  font-weight:600;
  color: #212d45;
  margin-bottom: 5px !important;
}

.dimensions-input {
  height: 38px;
  width: 70%;
  font-size:18px;
  margin-right: 5px;
  border-radius: 5px;
  border-width: 1px;
  border:2px solid #212d45;
  text-align: center;
}
.dimension-select-container{
  display:flex;
  align-items: center;
  margin-top: 9px;
}
.dimensions-select {
  height: 38px;
  color: #212d45;
  font-weight: bold;
  font-size: 15px;
  width: 20%;
  border:2px solid #212d45;
  border-radius:4px;
}

.dimensions-button {
  height: 40px;
  width: 150px;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  border-radius: 20px;
  outline: none;
  border-width: 0px;
  background-color: #212d45;
  cursor:pointer;
}
.dimension-input-container-1{
  display:flex;
  flex-direction:column;
  gap:4px;
  margin-bottom:10px;
  width:350px;
}
.update-password-background-container{
  background-image: url('../utlis/signup-bg-2.png') !important;
  background-size: cover;
  height:100vh;
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;
}
.update-password-main-container {
  display:flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  height:80vh;
  width:90%;
  border-radius:20px;
}
 
  .update-password-sub-container {
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    border:2px solid #f2f2f2;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
 
  .update-password-heading {
    text-align: center;
    color: #333;
    font-weight: bold;
    font-size: 22px;
  }
 
  .update-password-lable-container {
    display: block;
    margin-bottom: 8px;
    color: #555;
    font-weight: bold;
 
  }
 
  .update-password-input-container {
    width: 90%;
    padding: 8px;
    margin-bottom: 16px;
    border: none;
    border-radius: 4px;
    background-color: #f2f2f2;
    outline: none;
  }
 
  .update-password-button {
    width: 95%;
    padding: 7px;
    background-color:#212d45;
    color: #fff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 18px;
  }
 
  .update-password-button:hover {
    border: 1px solid #212d45;
    background-color: #fff;
    color:#212d45;
  }
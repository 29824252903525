.customer-profile-edit-main-container{
    display:flex;
    justify-content: center;
    width:100%;
    height:100%;
}
.customer-profile-edit-sub-container{
    display:flex;
    flex-direction: column;
    gap:14px;
}
.customer-profile-edit-heading{
    font-size:16px;
    margin:0px;
    font-weight:600;
    text-align: center;
}
.customer-profile-edit-input-container{
    display:flex;
    flex-direction: column;
    gap:2px;
}
.customer-profile-edit-name{
    font-size:18px;
    color:#212d45;
}
.customer-country-code{
    display:flex;
    align-items:center;
    gap:10px;
}
.customer-profile-edit-input-field{
    border:1px solid #ccc;
    border-radius:5px;
    padding:8px 10px;
    font-size:16px;
    outline:none;
}
.customer-profile-edit-input-field-select{
    border:1px solid #ccc;
    border-radius:5px;
    padding:8px 10px;
    font-size:16px;
    outline:none;
    width:100px;
}
.customer-profile-edit-file::file-selector-button{
    width:max-content;
    padding: 6px 20px;
    background-color: #212d45;
    color: #fff;
    border: none !important;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight:600;
    transition: background-color 0.3s;
    cursor:pointer;
    outline: none;
}
.customer-profile-edit-button{
    width:100%;
    margin-top:10px;
    padding: 6px 20px;
    background-color: #212d45;
    color: #fff;
    border: none !important;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
    font-weight:600;
    transition: background-color 0.3s;
    cursor:pointer;
    outline: none;
}
.signin-div-container{
  display:flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  height:90vh;
  width:92%;
  border-radius:20px;
  /* background-image: url("https://maamus.com/image/loginbg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
   */
}
.signin-form-main-container {
    width: 28%;
    padding: 20px 45px;
    border: 2px solid #f2f2f2;
    border-radius: 20px;
    background-color:#fff;
    margin:0px 10px;
    
  }
  .signin-form-heading-container{
    font-size:22px;
    font-weight:600;
    color:#212d45;
  }
  .singin-form-container{
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .signin-form-group-container {
    margin-bottom: 20px;
    width:94%;
    display: flex;
    flex-direction: column;
    gap:4px;
    
  }
  
.signin-form-label-container {
    font-size:18px;
    color:#212d45;
    
  }
  
  .signin-input-text {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    background-color: #f2f2f2;
    border-radius: 7px;
    outline:none;
    border:none;
  }
  
.signin-form-button-container {
    background-color: #212d45;
    color: #fff;
    padding: 7px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
    font-weight:600;
    width:100%;
  }
  .customer-signin-form-button-container{
    background-color: #212d45;
    color: #fff;
    padding: 7px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
    font-weight:600;
    width:100%;
  }
  .signin-form-button-container:hover {
    border: 1px solid  #4b4f58;
    color: #4b4f58;
    background-color: #fff;
    transition:0.3s ease-in-out;
  }
  .signin-staff-customer-button-container{
    display:flex;
    justify-content: space-between;
    flex-wrap:wrap;
    gap:4px;
    width:100%;
  }
  .signin-staff-button{
    border:none;
    width:45%;
    padding:8px 10px;
    background-color: #4b4f58;
    border-radius:4px;
    font-size:16px;
    color:#fff;
    cursor:pointer;
    font-weight:600;
    letter-spacing: 0.5px;
    min-width: max-content;
  }
  .signin-customer-button{
    border:none;
    width:45%;
    padding:8px 10px;
    border:1px solid #4b4f58;
    border-radius:4px;
    font-size:16px;
    color:#212d45;
    cursor:pointer;
    background-color: #fff;
    font-weight:600;
    min-width: max-content;
    letter-spacing: 0.5px;
  }
  .login-image-container{
    width:60%;
  }
  .Login-image{
    width:98%;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    .signin-form-main-container{
      width: 50%;
    }
  } 
  @media (max-width:1200px) {
    .signin-form-main-container{
      width: 30%;
    }
  } 
  @media  (min-width: 512px) and ( max-width: 768px) {
    .signin-form-main-container {
        width: 60%;
      
      }
      .login-image-container{
        display:none;
      }
      .signin-staff-button{
        width:100%;
      }
      .signin-customer-button{
        width:100%;
      }
      .signin-form-heading-container {
        font-size: 24px;
      }
  }
  @media (max-width:512px){
    .signin-form-main-container {
        width: 100%;
      
      }
      .login-image-container{
        display:none;
      }
      .signin-staff-button{
        width:100%;
      }
      .signin-customer-button{
        width:100%;
      }
  }
  
  
  
.common-navbar {
  /* background-image: linear-gradient(to Right,#ff9f00,#fedc00,#ffc500); */
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* opacity: 0.7; */
  background-color: #2884a1;
}

.common-menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
  color: #fff;
  cursor: pointer;
}
.common-navbar-logout-button-container {
  display: flex;
  align-items: center;
  gap: 20px;
}
.common-navbar-nav-item-name {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin: 0px;
}
.common-navbar-logout-button {
  background-color: #fff;
  border-radius: 20px;
  padding: 4px 12px;
  color: #212d45;
  margin-right: 20px;
  font-size: 16px;
  cursor: pointer;
  min-width: max-content;
  border: none;
}
